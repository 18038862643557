.modal {
  input[type=checkbox], input[type=radio], input[type=text], input[type=date], input[type=number],
  label, textarea, .help-block {
    font-size: .85em;
  }

  legend {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  .form-group {
    margin-bottom: 5px;
  }

  .check_boxes {
    span.checkbox, span.radio { padding-top: 0; min-height: 1.5em; }
  }

  p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .campo-detalhes {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: .9em;
  }

  .tabela-checkbox td, .tabela-checkbox th {
    font-size: .9em;
  }

  .tabela-checkbox .form-group {
    height: 7px;
    margin-top: -10px;
  }

  .pedido {
    color: navy;
  }
}