@import (reference) '../../../../stylesheets/bootstrap';

.ios-badge {
  position: absolute;
  bottom: 3px;
  right: -5px;
  padding: 2px 7px;
  border: 2px solid white;
  border-radius: 100px;
  background-color: #ff0000;
  // #gradient > .vertical(#FF6969, #ff0000);
  box-shadow: 0  1px  2px  rgba(0, 0, 0, .5),
              0  1px  4px  rgba(0, 0, 0, .4),
              0  0    1px  rgba(0, 0, 0, .7)        inset,
              0 10px  0px  rgba(255, 255, 255, .11) inset;
  -webkit-background-clip: padding-box;
  font: 10px/14px "Helvetica Neue", sans-serif;
  color: white;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .6);
}