.backtrace {
  font-size: 10pt;
  font-family: sans-serif;
  color: #777;
}

del {
  color: #999;
}

.home-buttons {
  text-align: center;
  min-height: 200px;
  padding-top: 80px;

  a {
    padding: 20px;
    width: 200px;
    font-size: 120%;
  }
}

.disabled {
  color: #a0a0a0;
}

.search .form-group { margin: 0; }

.c { text-align: center; }
.r { text-align: right; }
.l { text-align: left; }

.help-badge { margin-left: 5px; color: #bbb; cursor: default; }
.help-badge:hover { color: black; }

*[rel^=popover] { cursor: default; }

.nao-lido { font-weight: bold; }

.alert-with-icon {
  padding: .5em 1em;
  p { margin-bottom: 0; }
}

.progress {
  margin-bottom: 0;
}

.panel .panel-heading {
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: .9em;
  letter-spacing: 1px;
  line-height: 20px;
  .pull-right { margin-right: -5px; }
}

.panel.panel-default .panel-heading { color: #545454; background-color: white; border-bottom: dotted #ddd 1px; }

.list-group-item, .btn-block.l {
  i {
    margin-right: 7px;
  }
}

.sidetab {
  &, .btn { font-size: .95em; }
  li a { padding: 5px 7px; }
  .tab-pane {
    height: 19em;
    .vertical-stack {
      display: table;
      width: 100%;
      height: 100%;
      .footer { display: table-footer-group; .well { margin-bottom: 0; } }
    }
    .scrollable { height: 100%; overflow-y: scroll; }
  }
}

.tab-pane-perfil {
  .tab-pane { height: 45em;}
}

.tab-pane-relatorio {
  .tab-pane { height: 100%;}
}

.tab-pane-email {
  .tab-pane {
    height: 100%;
    min-height: 19em;
  }
}

.title-tabs {
  margin-top: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.inline-form-group-perfil {
  button, input, label { margin-right: 5px; }
}

// Classe criada para corrigir o bug de altura que ocorre no firefox quando aplicamos 'height: 100%;'.
// Se o bug for corrigido basta retirar esta classe.
.bug-firefox-admin {
  height: 15em !important;
}

//Classe criada para corrigir erro de login pelo celular no chrome.
.bug-chrome-login {
  a {
    position: relative;
    z-index: 1;
  }
}

.titulo-manifestacao {
  margin-bottom: 2px;
}

.mensagem-topo-layout {
  height: 100%;
  margin: auto 0 10px 10px;
  display: inline-block;
}

.mensagem-topo-layout div.alert {
  margin: 0 auto auto 0;
}

.mensagem-topo-layout.mensagem-download {
  margin-bottom: 0;
}

.mensagem-topo-layout.mensagem-download div.alert {
  padding-top: 4px;
  padding-bottom: 4px;
}

.page-header a.logo {
  display: inline-block;
}

.page-header a.logo div {
  margin-right: 25px;
  margin-bottom: 0;
}

.navbar-nav li.user-menu > a,
.navbar-nav li .botao-usuario {
  padding-bottom: 10px;
  padding-top: 10px;
}

.content-top {
  position: relative;
}

.clearfix-top {
  position: relative;
  margin-bottom: 5px;
}

.padd-left {
  padding-left: 2px !important;
}

.padd-right {
  padding-right: 2px !important;
}
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis p {
  display: -webkit-box;
  max-width: 375px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: normal;
}

.table-fixed {
  table-layout: fixed;
}

.coluna-dashboard {
  width: 500px;
  padding-right: 0
}

.busca-dashboard {
  margin-right: 12px;
  width: 450px;
}

@media (min-width: 1200px) {
  .tabela-dashboard {
    width: 333px;
    padding-right: 0
  }

  .tabela-dashboard-canceladas {
    width: 200px;
    padding-right: 0
  }

  .tabela-dashboard-fique-por-dentro {
    width: 466px;
    padding-right: 0
  }
}

@media (max-width: 620px) {
  .coluna-dashboard, .busca-dashboard {
    width: 400px;
  }
}

@media (max-width: 520px) {
  .coluna-dashboard, .busca-dashboard {
    width: 300px;
  }
}

@media (max-width: 450px) {
  .coluna-dashboard, .busca-dashboard {
    width: 250px;
  }
}

@media (max-width: 340px) {
  .coluna-dashboard, .busca-dashboard {
    width: 200px;
  }
}

@media (max-width: 300px) {
  .coluna-dashboard, .busca-dashboard {
    width: 180px;
  }
}

@media (max-width: 285px) {
  .coluna-dashboard, .busca-dashboard {
    width: 130px;
  }
}


.titulo-noticia, .texto-noticia {
  padding-right: 23px;
  padding-left: 15px;
}

.titulo-noticia{
  padding-top: 10px;
}

.texto-noticia {
  padding-bottom: 23px;
}

.data-noticia {
  padding-right: 23px;
}

.altura-quadro-inferior {
  height: 225px;
}