@import (reference) '../bootstrap';

.search-form {
  margin-top: 1em;
  .form-group { margin-bottom: 0; }
}

.search-results { font-size: .9em; padding-top: .5em; }

.search-filters {
  @media (min-width: @screen-lg-min) {
    position: absolute;
    right: 10px;
    top: -70px;
    margin-top: -20px;
    width: 80%;
  }
  margin-bottom: 1em;
  margin-top: -.5em;
  width: 100%;
  background-color: inherit;
  border: solid 3px white;
  border-radius: 5px;
  font-size: .9em;

  .row {
    padding: 1px 0;
    &.active { color: @list-group-active-text-color; background-color: @list-group-active-bg; }
  }

  .block {
    background-color: white; margin: 1px; padding: .5em;
    a { color: black; }
  }
  .filter-value { text-align: right; }
}

.search-filters.nfe {
  @media (min-width: @screen-lg-min) {
    top: -90px;
  }
}
