@import (reference) './effects';

section.alerts {
  position: fixed;
  z-index: 101;
  top: 1em;
  left: 0;
  right: 0;
  overflow: hidden;
  opacity: .95;

  .fade(0.01, 0.95)
}
