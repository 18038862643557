@import "~bootstrap/less/bootstrap.less";

// Customizations

@font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-base: 14px;

@navbarHeight: 30px;
@baseFontSize: 13px;
@table-bg-accent: #f9f9f9;

@baseColorForHeaderGradient: darken(#e2e2e2, 5%);

@left-navigation-box-width: 90px;
@left-navigation-box-height: @left-navigation-box-width * .55;
@content-padding: 1.5em;
@table-header-background: rgb(121, 121, 121);
@custom-pill-background: #ccc;

.btn-action {
  .button-variant(#FFFFFF; #424F63; #374152);
}

h1, h2, h3, h4 { margin-bottom: .5em; }
h1.screen-title { font-size: 1.6em; color: #444968; margin-top: 0; }
.modal-header, .popover { h1, h2, h3, h4 { margin-bottom: 0; } }
.popover { max-width: 400px; }
.popover p:last-of-type { margin-bottom: 0; }
.app-toolbox { padding: 10px; }
.well, .tab-content, .modal-header {
  h1, h2, h3, h4 { margin-top: 0; }
}
.tabbable .tab-content, .tab-content.tab-solid {
  background-color: white;
  padding: .5em;
}

.tab-solid {
  border: 1px solid rgb(221, 221, 221);
  border-top: none;
}

table.table {
  thead th {
    background-color: @table-header-background;
    color: white;
    font-weight: normal;
    a:link, a:visited { color: white; }
  }
  tbody tr { background-color: white; font-size: .85em }
}

.custom-pills(@width: inherit, @height: inherit) {
  li {
    > a {
      padding: 8px 0;
      border-radius: 0;
      background-color: @table-header-background;
      &:hover { background-color: lighten(@table-header-background, 10%); }
      color: white;
      text-align: center;
      font-size: .9em;
      width: @width;
      height: @height;
      position: relative;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-decoration: none;
    }
    &.active > a {
      color: white;
      background-color: rgb(0, 82, 255);
      &:hover { background-color: lighten(rgb(0, 82, 255), 10%); }
    }
  }
}

.navbar-app {
  max-width: 550px;
  margin-top: 32px;
  .navbar-inner, .nav { padding: 0; margin: 0; }
}

html, body {
  background-color: #aaa;
}

body > .container {
  background-size: 60%;
  background: rgb(168, 203, 232) url('../images/background-ce.png') no-repeat center right;
  padding: 0;
}

.navbar {
  background-color: rgb(236, 236, 236);
  margin-bottom: 0;
  border-radius: 0;
}

.page-header {
  margin: 0 0 1em @left-navigation-box-width;
  border-bottom: none;
  padding: 0 2em 0 0;

  a.logo {
    text-decoration: none;

    div {
      float: left;
      position: relative;
      margin-left: -66px;
      margin-right: 30px;
      background: url('../images/logo-consyste.png') no-repeat;
      width: 163px;
      height: 98px;
      margin-bottom: 10px;
    }
  }

  ul.nav {
    .custom-pills(97px);
    & > li + li { margin-left: 6px; }
  }
}

.presentation .jumbotron {
  background-image: url('../images/jumbotron-background.png');
  background-size: 100% 100%;
  * { visibility: hidden; }
}

.modules-menu {
  .custom-pills(@left-navigation-box-width, @left-navigation-box-height);

  width: @left-navigation-box-width;
  position: absolute;
  margin-right: 1em;
  z-index: 100;

  li {
    a {
      padding: .5em;
      text-align: right;
    }

    svg {
      position: absolute;
      bottom: 5px;
      left: 10px;
      .opacity(.075);
    }
  }
}

section.contents {
  background-color: rgba(235, 235, 235, 60%);
  min-height: 550px;
  &.has-modules-menu {
    padding-left: (@left-navigation-box-width + 20px);
    min-height: @left-navigation-box-height * 11 + 2px * 7;
  }
  position: relative;
  padding: @content-padding @content-padding 6em; // reserve space for the footer
}

footer {
  margin: 0 auto 0 auto;
  font-size: 8pt;
  color: #7f7f7f;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .5em;
  background-color: rgba(255, 255, 255, 50%);
}

.label { font-weight: 300; text-transform: uppercase; letter-spacing: 1px; font-size: .6em; padding: 5px 10px; }
time { cursor: help; border-bottom: 1px dotted @abbr-border-color; }

.monospaced-font {
  font-family: @font-family-monospace;
}

abbr[title] { text-decoration: none; } // correção para evitar o duplo sublinhado

.label-red {
  background-color: #D9534F;
  color: #FFF;
}

.label-gold {
  background-color: #FFD700;
  color: #808080;
}

.tooltip .tooltip-inner {
  width: 13em;
}

.svg-inline--fa {
  height: 0.9em !important;
}

.icone-situacao {
  height: 23px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.text-yellow {
  color: #f0ad4e;
}
