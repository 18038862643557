.panel {
  table.table, .alert {
    margin-bottom: 0;
  }

  .info-portaria {
    padding-top: 0;

    &.acoes-basicas {
      text-align: right;
    }

    .alert p {
      margin: 0;
    }
  }
}
