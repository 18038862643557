form { margin-bottom: 0; }
.modal form { margin: 0; }

.form-actions.noform {
  padding-left: 15px;
}

.show {
  legend { font-size: 1.2em; }
  legend + .control-group { margin-top: 0; }
}

// necessário para o react-datepicker, que utiliza "rem"
html { font-size: 14px; }

.filtros .form-group {
  margin-right: 4px;
}

.apos-campo {
  margin-top: 20px;
}

.busca-rapida {
  margin-top: 15px;
}

.busca-rapida-listas {
  margin-top: 15px;
  margin-bottom: -1.5em;
}

.campo-busca-rapida-nfse, .campo-busca-rapida-nfe, .campo-busca-rapida-cte {
  width: 330px;
  padding-right: 0;
  height: 36px;
}

.titulo-emissao {
  padding-left: 30px;
}

.campo-emissao-de {
  padding-right: 18px;
  padding-left: 19px;
  width: 197px;
}

.campo-emissao-ate {
  padding-right: 30px;
  padding-left: 10px;
  width: 200px;
}

.campo-intervalo-de {
  padding-right: 10px;
  padding-left: 0;
}

.campo-intervalo-ate {
  padding-right: 0;
  padding-left: 10px;
}

.campo-intervalo-de-nfse {
  padding-right: 10px;
  padding-left: 20px;
  width: 142px;
}

.campo-intervalo-ate-nfse {
  padding-left: 10px;
  width: 142px;
}

.campo-intervalo-de-cte {
  padding-right: 10px;
  padding-left: 0;
}

.campo-intervalo-ate-cte {
  padding-left: 10px;
  padding-right: 0;
}

.botao-limpar {
  margin-left: 22px;
}

.titulo-checkbox {
  text-align: right;
  padding-top: 0 !important;
}

.opcao-checkbox {
  margin-top: -1.5em;
}

.opcao-checkbox-nfse {
  margin-top: -.8em;
}

.margin-bottom-1 {
  margin-bottom: 1em;
}

.opcao-checkbox .form-group .checkbox {
  height: 12px;
}

.campo-formulario label {
  padding-top: 7px;
  text-align: right;
  margin-bottom: 10px;
}

.campo-booleano .form-group .col-sm-8 .help-block {
  margin-bottom: 2px;
  margin-top: 0;
}

.campo-booleano label {
  margin-bottom: 5px;
}

.campo-booleano .form-group .col-sm-8 {
  height: 28px;
}

.campo-input .form-group .col-sm-9 .help-block {
  margin-bottom: 5px;
  margin-top: 0;
}

.campo-intervalo-nfce {
  width: 50%;
}

.busca-estruturada input {
  font-size: .85em;
}

#busca-avancada-nfse {
  margin-top: -15px;
}

.titulo-grupo-nfce {
  margin-top: 8px;
}

.download-lista {
  padding-left: 8px;
  padding-right: 10px;
}

.download-lista .col-sm-9 {
  padding-left: 10px;
}

.instrucao-depois-campo-download-lista {
  margin-top: 20px;
}

.download-lista .control-label {
  padding-top: 9px;
}

.selecao-todos-checkbox {
  height: 20px;
}

.selecao-todos-checkbox div:first-child {
  margin-top: 10px;
}

// essa classe é aplicada somente ao campo SELECIONAR TODOS, na exportação de lista.
.selecionar-todos {
  position: relative;
  left: 73%;
}

.selecao-todos-texto {
  font-size: 1.1em;
  font-weight: bold;
}

.grupo-checkbox {
  padding-left: 10px;
}

.grupo-download-lista {
  margin-top: 10px;
}

.titulo-com-quebra-de-linha {
  margin-bottom: 25px;
}

.instrucao-em-titulo {
  font-size: 0.94em;
}

.download-lista .Select {
  font-size: .85em;
  height: 34px;
}

.botao-dashboard {
  padding-top: 4px;
  height: 26px;
}

.filtro-transparente .Select-control,
.botao-dashboard {
  background-color: rgba(255,255,255,0.2);
  font-size: 12px;
}

.Select.is-disabled > .Select-control {
  background-color: rgba(255,255,255,0.2);
}

.filtro-transparente .Select-control{
  height: 24px;
}

.filtro-transparente .Select-menu-outer {
  font-size: 12px;
}

.filtro-transparente .Select-control .Select-placeholder,
.filtro-transparente .Select-control .Select-input {
  height: 24px;
}

.filtro-transparente .Select-control .Select-multi-value-wrapper .Select-placeholder,
.filtro-transparente .Select-control .Select-multi-value-wrapper .Select-value {
  line-height: 24px;
}

section.detalhes-empresa legend {
  font-size: 1.2em;
}

.cor-decisao-portaria-com-pendencia {
  color: yellow;
}

.amarelo-icone {
  color: #D1AF26;
}

.verde-icone {
  color: #26D162;
}

.vermelho-icone {
  color: #D12B26;
}

.cinza-icone {
  color: #BEBEBE;
}

.transparente {
  opacity: 0.0;
}

.icone-certificado-expirando {
  --fa-primary-color: white;
  --fa-secondary-color: #26D162;
  --fa-secondary-opacity: 1.0;
}

.opacity-075 {
  opacity: .75;
}

.intervalo-data {
  padding-bottom: 5px;
}

.rw-widget-container {
  min-height: 34px !important;
}

.padding-sides-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.subitem-doc-api {
  position: relative;
  display: block;
  padding: 0 0 0 20px;
}
