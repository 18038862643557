.fade(@min: 0.01, @max: 1) {
  .fade-enter {
    opacity: @min;
  }

  .fade-enter.fade-enter-active {
    opacity: @max;
    transition: opacity 500ms ease-in;
  }

  .fade-leave {
    opacity: @max;
  }

  .fade-leave.fade-leave-active {
    opacity: @min;
    transition: opacity 300ms ease-in;
  }
}