@import (reference) '../../../../stylesheets/bootstrap';

.update-nag {
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  height: 40px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .4);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .3);
  }

  & > .update-split {
    background: #337ab7;
    width: 33px;
    float: left;
    color: #fff !important;
    height: 100%;
    text-align: center;

    & > .glyphicon,
    & > .svg-inline--fa {
      position: relative;
      top: ~"calc(50% - 9px)" !important; /* 50% - 3/4 of icon height */
    }

    &.update-success {
      background: @label-success-bg;
      color: @label-color;
    }

    &.update-danger {
      background: @label-danger-bg;
      color: @label-color;
    }

    &.update-info {
      background: @label-info-bg;
      color: @label-color;
    }

    &.update-warning {
      background: @label-warning-bg;
      color: @label-color;
    }
  }

  & > .update-text {
    line-height: 19px;
    padding-top: 11px;
    padding-left: 45px;
    padding-right: 15px;
  }
}