@selected_color: #c7eefe;
@selected_odd_color: #b6d9e8;
@selected_hover_color: #a4c2d1;

tr[data-url], th.check, th.fill, th.col-select {
  cursor: pointer;
}

table.table thead th { text-align: left; vertical-align: middle; }
table.table thead th.check { text-align: center; }
table.table-bordered thead th { text-align: center; }

table tbody {
  tr.selected td,
  tr.selected th {
    background-color: @selected_color;
  }

  tr.selected:nth-child(odd) td,
  tr.selected:nth-child(odd) th {
    background-color: @selected_odd_color;
  }

  &.table-hover {
    tr.selected:hover td,
    tr.selected:hover th {
      background-color: @selected_hover_color;
    }
  }
}

table.table td .control-group { margin-bottom: -10px; }

table.table {
  .control-group, .controls { margin: -2px !important; }
  &.reposition-inputs .control-group { margin: 0 -4px -4 -4px; }
  input[type=number] { width: 3.5em; text-align: center; }
  input[max='0'] { background-color: #e3e3e3; }
}

.table-footer {
  min-height: 3em;
}

.table-footer .pagination {
  margin: 0;
  float: right;
}

.table-footer .page-entries {
  float: left;
  padding-left: 1em;
  padding-top: 0.75em;
}

.table-actions { text-align: center; }

table tbody tr.nodata td { text-align: center; font-style: italic; padding: 25px 0; }
table tbody tr.nodata.padding-10 td { padding: 10px; }
table tbody tr.nodata.carga td { padding-top: 10px; }
div.c, td.c, th.c { text-align: center; .radio input, .checkbox input { margin-left: 0; } }
div.r, td.r, th.r { text-align: right; }

table thead th { text-align: center; }

table caption { caption-side: bottom; text-align: right; font-size: 8pt; color: #dadada; }
table caption.hidden { display: none; }

table.table input.filled { background-color: @selected_color; }
table.table input.maxed { background-color: @selected_odd_color; }

table.table tr.template { display: none; }

table.table-details-view {
  th, td { font-size: 8.5pt; }
  th { text-align: left; font-weight: normal; color: #7D2B3D; }
}

.link-button button, .link-button button:hover, .link-button button:active, .link-button button:focus, .link-button button:visited {
  color: #FFFFFF;
}

.text-desacordo {
  color: #FFD700;
}

.text-desacordo-sem-retorno {
  color: #F0AD4E;
}

.text-desacordo-rejeitado {
  color: #D9534F;
}

.key-button button, .link-button button:hover, .link-button button:active, .link-button button:focus, .link-button button:visited {
  outline: none;
}

.table-documento thead tr th span button {
  padding-top: 0;
  padding-bottom: 0;
}

.table-documento {
  font-weight: bold;
}

.table-documento.nfce, .table-documento.nfe, .table-documento.cte, .table-documento.cfe {
  font-weight: normal;
}

.tooltip-td-table-documento {
  cursor: help;
  border-bottom: 1px dotted #777777;
}

.botao-informacoes, .botao-informacoes:hover, .botao-informacoes:active, .botao-informacoes:focus, .botao-informacoes:visited {
  background-color: #eeeeee;
  border: white;
}

.botao-informacoes.no-padding {
  padding: 0;
}

.coluna-informacoes {
  padding-top: 1px;
  padding-right: 0;
  padding-left: 15px;
  width: 35px;
  height: 34px;
}

.detalhes-documento {
  margin-bottom: 15px;
}

.botao-voltar-top {
  margin-bottom: 5px;
  margin-right: 1px;
}

.botao-historico {
  text-align: left;
  margin-bottom: 18px;
}

.botao-historico i {
  margin-right: 6px;
}

.modal-historico {
  width: 750px;
}

.dropup .dropdown-menu .btn-lg {
  padding: 2px 16px 2px 16px;
}

.caret-up-exportacao-lote {
  margin-left: 10px;
}

.btn-group-vertical > .btn:first-child:not(:last-child){
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-group-vertical {
  margin-right: 6px;
}

.btn-exportacao-lote, .btn-exportacao-lote:hover, .btn-exportacao-lote:active, .btn-exportacao-lote:focus {
  font-size: 14px;
  color: #262626;
  text-align: left !important;
  text-decoration: none;
}

.btn-exportacao-lote:hover {
  background-color: #F5F5F5;
}

.column-sped-small {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.column-sped-c {
  padding-left: 2px !important;
  padding-right: 4px !important;
  text-align: right !important;
}

.titulo-dashboard {
  text-anchor: start;
  fill: rgb(0, 0, 0);
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 10px;
  text-align: right;
}

.titulo-nfes-canceladas-dashboard {
  padding-bottom: 0 !important;
  margin-bottom: -5px;
}

.botao-link-dashboard, .botao-link-noticia {
  font-size: 15px;
  padding-top: 2px;
}

.botao-link-noticia {
  padding: 0;
  text-align: left;
  white-space: normal;
  position: relative;
}

.botao-link-nfes-canceladas {
  padding: 0;
  text-align: center;
  white-space: normal;
  background-color: #ffffff6b !important;
}

.lista-noticia {
  padding-left: 5px;
  list-style: none;
  margin-bottom: 14px;
}

.coluna-link-noticia {
  padding-left: 5px;
  padding-right: 12px;
}

.gray {
  color: #777777;
}

.espaco-icon-recebidos {
  padding-left: 10px;
}

.quantidade-doc-dashboard {
  padding-left: 14px;
}

.texto-doc-cancelado {
  color: #e75512;
}

.quebra-de-linha {
  overflow-wrap: break-word;
  min-width: 5em;
  max-width: 5em;
}

.a-btn, .a-btn:hover {
  color: white;
  cursor: pointer;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: inline !important;
}

.no-padding-left {
  @media only screen and (max-device-width: 640px) {
    padding-left: 15px;
  }

  @media (max-width: 700px) {
    padding-left: 15px;
  }

  padding-left: 0;
}
