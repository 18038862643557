@import (reference) '../bootstrap.less';

.visualizacao-dados {
  padding: 10px;
  background-color: rgba(255, 255, 255, 50%);

  fieldset legend { font-size: 1.1em; }

  .table-main {
    border: 1px solid #dddddd;
  }

  table.table tbody th {
    background-color: #f6f6f6;
  }

  ul.nav-tabs li a {
    font-size: .85em;
    padding: 6px 12px
  }
  .tab-content {
    border: 1px solid #dddddd;
    border-top: none;
  }
  .tab-pane {
    background-color: white;
    padding: .5em;
  }
  h3 {
    font-size: 1.15em;
    margin-left: 5px;
  }
  h4 {
    font-size: 1em;
    margin-left: 5px;
  }
  pre {
    white-space: pre-wrap;
  }
  .form-group {
    margin-bottom: 0;
  }
}

.visualizacao-documento {
  .visualizacao-dados();

  .tab-pane {
    min-height: 35em;
  }
}

.visualizacao-documento-api {
  padding: 10px;
  background-color: rgba(255, 255, 255, 50%);

  table, th, td {
    border: 1px solid #CCC;
    border-spacing: 0;
    border-collapse: collapse;
    padding: 5px;
    background-color: rgba(255, 255, 255, 75%);
  }

  a:link {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: 1.8em;
    margin-left: 5px;
  }
  h3 {
    font-size: 1.6em;
    margin-left: 5px;
  }
  h4 {
    font-size: 1.4em;
    margin-left: 5px;
  }
  h5 {
    font-size: 1.2em;
    margin-left: 8px;
  }
  pre {
    white-space: pre-wrap;
  }
  p {
    margin-left: 10px;
  }
}

.situacoes {
  dl {
    margin: 0;
    dt {
      font-weight: normal;
      font-size: .9em;
    }
    dd {
      margin-bottom: .5em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .ultima-consulta {
    color: @text-muted;
    font-size: .8em;
    margin-top: 1.25em;
  }
}

.acoes {
  a {
    color: black;
    &.disabled {
      color: @btn-link-disabled-color;
      background-color: @input-bg-disabled;
      cursor: not-allowed;
    }
  }
}

.situacao-label, .painel-custodia .update-nag .update-text {
  white-space: nowrap;
  .fa-info-circle {
    color: #777;
  }
}

.opaco {
  opacity: 0.5
}

@media (min-width: 992px) {
  .padding-bottom-zoom {
    padding-bottom: 1em;
  }
}

@media (max-width: 992px) {
  .padding-bottom-zoom {
    padding-bottom: 6em;
  }
}
