.novos-alertas-enter {
  opacity: 0.01;
}

.novos-alertas-enter.novos-alertas-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.novos-alertas-exit {
  opacity: 1;
}

.novos-alertas-exit.novos-alertas-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}