@import './bootstrap.less';
@import './overrides.less';
@import '../vendor/autocomplete.css';
@import '../vendor/jquery.loadmask.css';
@import '~select2/dist/css/select2.css';
@import '~select2-bootstrap-theme/dist/select2-bootstrap.css';
@import '~react-select/dist/react-select.css';
@import '~react-virtualized/styles.css';
@import '~react-virtualized-select/styles.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-widgets/lib/less/react-widgets';

@import './forms';
@import './tables';
@import './alerts';

@import './domain/destinadas';
@import './domain/modais';
@import './domain/portaria';
@import './domain/search';
@import './domain/visualizacoes';
