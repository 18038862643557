.sped-filtros {
  margin-bottom: 1em;

  .sped-busca-avancada, .sped-busca-basica {
    padding-bottom: .5em;

    .navbar-form {
      padding: 0;
    }

    .navbar-form, .navbar-header {
      width: 100%;
    }

    .navbar-form.botoes {
      margin-top: 1.5em;
    }
  }

  .sped-busca-avancada {
    .form-group, .form-control {
      display: block;
      width: 100%;
    }
  }
}